import axios from 'axios'
import utils from 'services/utils'
import { setLists, showNotification } from './store/actions'
import intl from 'services/intl'

export default class DataProvider {
  static fetch({
    route = null,
    headers = {},
    params = null, // params in the URL
    data = {}, // body of the request
    method = 'GET', // GET by default
    responseType = 'json'
  }) {
    const url =
      process.env.REACT_APP_API_URL +
      route +
      (route.indexOf('?') === -1 ? '?lang=' : '&lang=') +
      utils.app.get('language', false)
    const _headers = {
      'Content-Type': 'application/json'
    }
    const sessionToken = utils.app.get('sessionToken', false)

    if (sessionToken) {
      _headers.Authorization = `Bearer ${sessionToken}`
    }

    return axios({
      url,
      headers: Object.assign({}, _headers, headers),
      params,
      data,
      method,
      responseType
    })
      .then(function(response) {
        return [null, response]
      })
      .catch(function(error) {
        return [error]
      })
  }

  static format([wsError, wsResponse]) {
    const args = {
      data: null,
      error: false
    }

    if (wsError) {
      args.error = true

      if (wsError.response) {
        const {
          response: { status, data }
        } = wsError

        if (status === 401) {
          window.location.hash = '/logout'
          showNotification(
            'error',
            'Session expirée',
            intl`La session a expiré, veuillez vous reconnecter.`
          )
        } else if (status === 500) {
          showNotification('error', 'Une erreur est survenue', intl`Veuillez réessayer.`)
        } else {
          args.data = data
        }
      } else {
        window.location.hash = '/logout'
        showNotification('error', 'Une erreur est survenue', intl`Vous avez été déconnecté.`)
      }
    } else {
      const {
        data: { payload, error }
      } = wsResponse

      args.error = !!error
      args.data = error || payload
    }

    return args
  }

  static async login(username, password) {
    const response = await DataProvider.fetch({
      route: '/login_check',
      method: 'POST',
      data: JSON.stringify({
        username,
        password
      })
    })

    return DataProvider.format(response)
  }

  static async getUser() {
    const response = await DataProvider.fetch({
      route: '/user'
    })

    return DataProvider.format(response)
  }

  static async getUserAgreement() {
    const response = await DataProvider.fetch({
      route: '/user/agreements'
    })

    return DataProvider.format(response)
  }

  static async createAgreement() {
    const response = await DataProvider.fetch({
      route: '/agreement',
      method: 'POST'
    })

    return DataProvider.format(response)
  }

  static async getLists() {
    const { data, error } = DataProvider.format(
      await DataProvider.fetch({
        route: `/lists`
      })
    )

    if (error) {
    } else {
      setLists(data)
    }
  }
  
  static async getPublicAgreementTypes() {
    const { data, error } = DataProvider.format(
      await DataProvider.fetch({
        route: `/agreement_types`
      })
    )

    if (error) {
    } else {
      setLists(data)
    }
  }

  static async getAgreement(id) {
    const response = await DataProvider.fetch({
      route: `/agreement/${id}`
    })

    return DataProvider.format(response)
  }

  static async updateAgreement(id, data) {
    const response = await DataProvider.fetch({
      route: `/agreement/${id}`,
      method: 'PUT',
      data: JSON.stringify(data)
    })

    return DataProvider.format(response)
  }

  static async updateUser(data) {
    const response = await DataProvider.fetch({
      route: '/user',
      method: 'PUT',
      data: JSON.stringify(data)
    })

    return DataProvider.format(response)
  }

  static async updatePartner(data) {
    const response = await DataProvider.fetch({
      route: '/user/partner',
      method: 'POST',
      data: JSON.stringify(data)
    })

    return DataProvider.format(response)
  }

  static async validateAgreement(id, data) {
    const response = await DataProvider.fetch({
      route: `/agreement/${id}/validate`,
      method: 'POST',
      data: JSON.stringify(data)
    })

    return DataProvider.format(response)
  }

  static async getTitleList() {
    const response = await DataProvider.fetch({
      route: `/titles`
    })

    return DataProvider.format(response)
  }

  static async register(data) {
    const response = await DataProvider.fetch({
      route: `/register`,
      method: 'POST',
      data: JSON.stringify(data)
    })

    return DataProvider.format(response)
  }

  static async validateAccount(token) {
    const response = await DataProvider.fetch({
      route: `/account/confirm?token=${token} `
    })

    return DataProvider.format(response)
  }

  static async updateUserAccount(data) {
    const response = await DataProvider.fetch({
      route: '/user/account',
      method: 'PUT',
      data: JSON.stringify(data)
    })

    return DataProvider.format(response)
  }

  static async deleteUserAccount(data) {
    const response = await DataProvider.fetch({
      route: '/user',
      method: 'DELETE',
      data: JSON.stringify(data)
    })

    return DataProvider.format(response)
  }

  static async resetPassword(data) {
    const response = await DataProvider.fetch({
      route: `/account/reset/request`,
      method: 'POST',
      data: JSON.stringify(data)
    })

    return DataProvider.format(response)
  }

  static async resetPasswordFinal(token, data) {
    const response = await DataProvider.fetch({
      route: `/account/reset/confirm?token=${token} `,
      method: 'POST',
      data: JSON.stringify(data)
    })

    return DataProvider.format(response)
  }
  
  static async defineForeignPassword(token, data) {
    const response = await DataProvider.fetch({
      route: `/register/foreign/confirm?token=${token} `,
      method: 'POST',
      data: JSON.stringify(data)
    })

    return DataProvider.format(response)
  }

  static async checkAccount(data) {
    const response = await DataProvider.fetch({
      route: `/webservice/fg-account`,
      method: 'POST',
      data: JSON.stringify(data)
    })

    return DataProvider.format(response)
  }

  static async searchCity(zip) {
    const response = await DataProvider.fetch({
      route: `/webservice/city?zipCode=${zip}`
    })

    return DataProvider.format(response)
  }
}
