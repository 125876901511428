// Allows to define the route permissions (public, private or both) according to the user status

import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'services/store'

const RoutePermissions = ({ isAuthenticated, location, type, Component }) => {
  if (type === 'public' && isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: location }
        }}
      />
    )
  } else if (type === 'private' && !isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location }
        }}
      />
    )
  } else {
    return <Component />
  }
}

const mapStateToProps = ({ app: { isAuthenticated }, location, type, Component }) => {
  return { isAuthenticated, location, type, Component }
}

export default withRouter(connect(mapStateToProps)(RoutePermissions))
