import React, { Component } from 'react'
import styled from 'services/style'
import intl from "../services/intl"

const MainCSS = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
  }
  .lds-dual-ring:after {
    content: ' ';
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border-width: 3px;
    border-style: solid;
    border-color: #000000 transparent #000000 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

class Loader extends Component {
  render() {
    return (
      <MainCSS color={this.props.color}>
        <div className="lds-dual-ring" />
        <div>{intl`Veuillez patienter`}</div>
      </MainCSS>
    )
  }
}

export default Loader
