import React, { lazy } from 'react'
import { RoutePermissions } from 'components'
import Login from './login/Login'
import Logout from './logout/Logout'

const routes = [
  {
    path: '/',
    exact: true,
    render: () => <RoutePermissions type="private" Component={lazy(() => import('./home/Home'))} />
  },
  {
    name: 'login',
    path: '/login',
    render: () => <RoutePermissions type="public" Component={Login} />
  },
  {
    path: '/logout',
    exact: true,
    component: Logout
  },
  {
    path: '/account',
    render: () => (
      <RoutePermissions type="private" Component={lazy(() => import('./account/Account'))} />
    )
  },
  {
    path: '/agreements/:code',
    render: () => (
      <RoutePermissions type="both" Component={lazy(() => import('./agreements/Agreements'))} />
    )
  },
  {
    path: '/enrollment/:id?',
    render: () => (
      <RoutePermissions type="private" Component={lazy(() => import('./enrollment/Enrollment'))} />
    )
  },
  {
    path: '/accountCreate',
    render: () => (
      <RoutePermissions
        type="public"
        Component={lazy(() => import('./accountCreate/AccountCreate'))}
      />
    )
  },
  {
    path: '/createForeignAccount/:token',
    render: () => (
      <RoutePermissions
        type="public"
        Component={lazy(() => import('./createForeignAccount/CreateForeignAccount'))}
      />
    )
  },
  {
    path: '/accountValidation/:token',
    render: () => (
      <RoutePermissions
        type="both"
        Component={lazy(() => import('./accountValidation/AccountValidation'))}
      />
    )
  },
  {
    path: '/resetPassword/:token',
    render: () => (
      <RoutePermissions
        type="both"
        Component={lazy(() => import('./resetPassword/ResetPassword'))}
      />
    )
  }
]

export default routes
