import React, { useEffect, useRef, useState } from 'react'
import styled from 'services/style'
import { DATE_FORMAT, DATE_FORMAT_REGEX } from 'services/constants'
import intl from 'services/intl'
import { Datepicker } from 'components'
import { ReactComponent as CalendarIcon } from 'assets/svg/calendar.svg'
import { ReactComponent as DeleteIcon } from 'assets/svg/delete.svg'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utils from '../services/utils'

dayjs.extend(customParseFormat)

const MainCSS = styled.div`
  position: relative;
  background-color: #ffffff;
  .idp-delete {
    position: absolute;
    right: 18px;
    top: -6px;
    width: 40px;
    height: 40px;
    z-index: 2;
  }
  .idp-delete-icon {
    width: 10px;
    height: 10px;
    fill: #454545;
  }
  .idp-datepicker {
    position: absolute;
    z-index: 1000;
    right: 0;
    top: calc(100% + 10px);
  }
  .idp-icon {
    position: absolute;
    right: 5px;
    top: 50%;
    padding-left: 5px;
    transform: translateY(-50%);
    width: 22px;
    height: 25px;
    border-left: 1px solid #e6e6e6;
  }
  .fg-inputText {
    position: relative;
    z-index: 1;
    background-color: transparent;
  }
`

const InputDagepicker = ({
  context,
  now = null,
  date: _date = null,
  minDate = null,
  maxDate = null,
  ...props
}) => {
  const [date, setDate] = useState(_date ? dayjs(_date).format(DATE_FORMAT) : null)
  const [isDatepickerVisible, setVisible] = useState(false)
  const refForInput = useRef(null)
  const handleOpenDatepicker = () => {
    context.stopPropagation = true
    context.isDatepickerVisible = true
    setVisible(true)
  }
  const handleClickMain = () => {
    context.stopPropagation = true
  }
  const handleChange = newDate => {
    setDate(newDate.format(DATE_FORMAT))
    setVisible(false)
    context.isDatepickerVisible = false
    setTimeout(validation, 0)
  }

  const handleReset = () => {
    setDate(null)
    setTimeout(validation, 0)
  }

  const validation = () => {
    const element = refForInput.current
    const parent = utils.closest(element, '.fg-field')
    const error = parent.querySelector('.fg-fieldError')

    if (!element.value) {
      if (element.dataset.hasOwnProperty('required')) {
        error.textContent = intl`Champ obligatoire`
        error.style.display = 'block'
      } else {
        error.style.display = 'none'
      }
      return
    }

    if (DATE_FORMAT_REGEX.test(element.value)) {
      error.style.display = 'none'
    } else {
      error.textContent = intl`La date est invalide`
      error.style.display = 'block'
    }
  }

  useEffect(() => {
    const handleClick = () => {
      if (context.stopPropagation) {
        context.stopPropagation = false
        return
      }
      if (isDatepickerVisible) {
        setVisible(false)
        context.isDatepickerVisible = false
      }
    }

    window.addEventListener('click', handleClick, false)
    return () => {
      window.removeEventListener('click', handleClick, false)
    }
  }, [context, isDatepickerVisible, setVisible])

  const componentDidUpdate = () => {
    if (props.disabled) {
      handleReset()
    }
  }

  useEffect(componentDidUpdate, [props.disabled])

  return (
    <MainCSS>
      {date && (
        <button type="button" className="idp-delete" onClick={handleReset}>
          <DeleteIcon className="idp-delete-icon" />
        </button>
      )}
      <input
        ref={refForInput}
        onClick={handleOpenDatepicker}
        autoComplete={'off'}
        placeholder={DATE_FORMAT}
        {...props}
        value={date || ''}
        maxLength={10}
        data-required-type="date"
        onBlur={() =>
          setTimeout(() => {
            if (!context.isDatepickerVisible) {
              validation()
            }
          }, 100)
        }
        onChange={event => {
          const value = event.target.value.trim().replace(/[^0-9/]/g, '')
          setDate(value)
        }}
      />
      <CalendarIcon className="idp-icon" />
      <div onClick={handleClickMain} className="idp-datepicker">
        {isDatepickerVisible && (
          <Datepicker
            key={date || performance.now()}
            now={now}
            minDate={minDate}
            maxDate={maxDate}
            date={DATE_FORMAT_REGEX.test(date) ? dayjs(date, DATE_FORMAT) : null}
            onChange={handleChange}
          />
        )}
      </div>
    </MainCSS>
  )
}

export default props => {
  const context = {}
  return <InputDagepicker {...props} context={context} />
}
