import {SET_USER, CLEAR_USER, CLEAR_STORE} from '../actions'

const getInitialState = () => {
  return {}
}

function reducer(state = getInitialState(), action) {
  switch (action.type) {
    case SET_USER:
      return action.user
    case CLEAR_USER:
      return getInitialState()
    case CLEAR_STORE:
      return getInitialState()
    default:
      return state
  }
}

export default [reducer, getInitialState()]
