import React from 'react'
import intl from 'services/intl'
import { showNotification } from 'services/store/actions'

class ErrorBoundary extends React.Component {
  componentDidCatch() {
    showNotification('warning', intl`Erreur`, intl`Une erreur est survenue.`, {
      title: intl`Réessayer`,
      handler: () => {
        window.location.reload()
      }
    })
  }

  render() {
    return this.props.children
  }
}

export default ErrorBoundary
