export default {
  closest(el, selector) {
    let matchesFn

      // find vendor prefix
    ;[
      'matches',
      'webkitMatchesSelector',
      'mozMatchesSelector',
      'msMatchesSelector',
      'oMatchesSelector'
    ].some(function(fn) {
      if (typeof document.body[fn] === 'function') {
        matchesFn = fn
        return true
      }
      return false
    })

    let parent

    // traverse parents
    while (el) {
      parent = el.parentElement
      if (parent && parent[matchesFn](selector)) {
        return parent
      }
      el = parent
    }

    return null
  },

  toUrlEncoded(obj) {
    return Object.keys(obj)
      .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k]))
      .join('&')
  },

  deepCamelcaseKeys(obj) {
    const objToStringToArr = JSON.stringify(obj)
      .replace(/":/g, '":[S]')
      .split('[S]')

    const ucFirst = function(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }

    const newString = objToStringToArr.reduce((acc, str) => {
      const m = str.match(/"([a-z]+_?)+":/g)

      return m
        ? acc.concat(
            str.replace(
              m[0],
              m[0].split('_').reduce((acc2, str, i) => {
                return i ? acc2 + ucFirst(str) : acc2 + str
              }, '')
            )
          )
        : acc + str
    }, '')

    return JSON.parse(newString)
  },

  // localStorage hooks
  app: {
    get(key, parse = true) {
      let item = localStorage.getItem(key)

      if (!item) return null

      if (parse) {
        try {
          item = JSON.parse(item)
        } catch (error) {
          console.log(error)
        }
      }

      return item
    },

    set(key, value) {
      localStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : value)
    },

    remove(key) {
      localStorage.removeItem(key)
    }
  },

  numberFormat(amount) {
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(amount)
  },

  dateFormat(date) {
    if (date instanceof Date) {
      return `${date.getDate()}.${
        date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      }.${date.getFullYear()}`
    } else {
      const arr = date.split('T')[0].split('-')
      return `${arr[2]}.${arr[1]}.${arr[0]}`
    }
  },

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  },

  getFieldsByName(fields) {
    return fields.reduce((acc, field, index) => {
      if (!field.name) {
        console.error(`field index ${index}`)
      }

      if (
        field.nodeName === 'INPUT' ||
        field.nodeName === 'SELECT' ||
        field.nodeName === 'TEXTAREA'
      ) {
        if (field.type === 'file') {
          acc[field.name] = field.files
        } else if ((field.type !== 'radio' && field.type !== 'checkbox') || field.checked) {
          acc[field.name] = field.value
        }
      }

      return acc
    }, {})
  },

  navigatorLanguage(short = true) {
    let lang =
      (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      (navigator.userLanguage &&
        navigator.userLanguage.replace(/-[a-z]{2}$/, String.prototype.toUpperCase)) ||
      'en_GB'

    //format navigator language (ex fr_FR)
    if (short) {
      lang = lang.substr(0, 2)
    } else {
      if (lang.length === 2) {
        lang = lang + '_' + lang.toUpperCase()
      } else {
        lang = lang.replace(/-/g, '_').replace(/_[a-z]{2}$/, function(v) {
          return v.toUpperCase()
        })
      }
    }

    return lang
  },

  getPrices(agreementTypes, agreementCode) {
    let prices = null

    for (let i in agreementTypes) {
      if (agreementTypes.hasOwnProperty(i)) {
        const result = agreementTypes[i].filter(item => item.code === agreementCode)

        if (result && result.length) {
          prices = result[0].prices
        }
      }
    }

    return prices
  }
}
