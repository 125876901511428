import React, { Component } from 'react'
import { connect } from 'services/store'
import { hideNotification } from 'services/store/actions'
import { Notification, Modal } from 'components'

class NotificationModal extends Component {
  render() {
    if (!this.props.notification) return null

    const {
      notification: { type, title, text, button, textHtml = false }
    } = this.props
    return (
      <Modal onClose={hideNotification}>
        <Notification type={type} title={title} button={button}>
          {text && (textHtml ? <div dangerouslySetInnerHTML={{ __html: text }} /> : <p>{text}</p>)}
        </Notification>
      </Modal>
    )
  }
}

const mapSateToProps = ({ view: { notification } }) => {
  return {
    notification
  }
}

export default connect(mapSateToProps)(NotificationModal)
