import React from 'react'
import ReactDOM from 'react-dom'
import { unregister } from './registerServiceWorker'
import 'url-search-params-polyfill'
import 'react-app-polyfill/ie11'

import './assets/style/normalized.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import utils from 'services/utils'
import App from './App'

if (!utils.app.get('language', false)) {
  utils.app.set('language', utils.navigatorLanguage())
}

// Clear storage data when the app version change
if (process.env.REACT_APP_VERSION !== localStorage.version) {
  localStorage.clear()
  localStorage.setItem('version', process.env.REACT_APP_VERSION)
}

unregister()

ReactDOM.render(<App />, document.getElementById('root'))
