import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'services/store'
import { ErrorBoundary, Header } from 'components'
import styled from 'services/style'

export const NavMenu = ({ children }) => {
  const navMenuElement = document.getElementById('MainTemplate-navMenu')

  if (!navMenuElement) return null

  return ReactDOM.createPortal(children, navMenuElement)
}

export const Content = ({ children }) => {
  const contentElement = document.getElementById('MainTemplate-content')

  if (!contentElement) return null

  return ReactDOM.createPortal(children, contentElement)
}

const MainCSS = styled.div`
  position: relative;
  z-index: 1;
  .MainTemplate-content {
    position: relative;
    margin: 0 auto 50px;
    max-width: 750px;
    width: 100%;
    background-color: #ffffff;
  }
`

const MainTemplate = () => {
  return (
    <>
      <MainCSS className="container">
        <Header />
        <div id="MainTemplate-navMenu" className="MainTemplate-navMenu" />
        <ErrorBoundary>
          <div id="MainTemplate-content" className="MainTemplate-content" />
        </ErrorBoundary>
      </MainCSS>
    </>
  )
}

const mapStateToProps = () => {
  return {}
}

export default connect(
  mapStateToProps,
  true
)(MainTemplate)
