import React, { memo, Suspense } from 'react'
import { HashRouter as Router, Switch, Route } from 'react-router-dom'
import 'dayjs/locale/fr'
import dayjs from 'dayjs'
import routes from 'routes'
import { storeProvider } from 'services/store'
import { GlobalStyle } from 'services/style'
import { Loader, MainTemplate } from 'components'
import { NotificationModal } from 'components'

dayjs.locale('fr') // use French locale globally

const App = memo(
  () => {
    return (
      <>
        <GlobalStyle />
        <NotificationModal />
        <Router>
          <MainTemplate />
          <Suspense fallback={<Loader />}>
            <Switch>
              {routes.map((conf, key) => (
                <Route key={key} {...conf} />
              ))}
            </Switch>
          </Suspense>
        </Router>
      </>
    )
  },
  () => true // Always return true to prevent updates
)

export default storeProvider(App)
