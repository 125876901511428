import React from 'react'
import { Content } from 'components/MainTemplate'
import HomeSlider from '../home/HomeSlider'
import LoginForm from './LoginForm'
import LoginPasswordModal from './LoginPasswordModal'

const LoginView = () => {
  return (
    <Content>
      <>
        <HomeSlider />
        <LoginForm />
        <LoginPasswordModal />
      </>
    </Content>
  )
}

export default LoginView
