import React from 'react'
import useViewState from 'hooks/useViewState'
import utils from 'services/utils'
import {
  setState,
  setUser,
  setPassword,
  setUserConnected,
  showNotification
} from 'services/store/actions'
import DataProvider from 'services/DataProvider'
import intl from 'services/intl'
import { Loader } from 'components'
import LoginView from './LoginView'

const Login = () => {
  const handleLogin = async (identifier, password) => {
    setState({ isLoging: true })

    const { data: loginData, error: loginError } = await DataProvider.login(identifier, password)

    if (loginError) {
      setState({ loginFailed: true, isLoging: false })
    } else {
      utils.app.set('sessionToken', loginData.token)
      setPassword(password)

      const { data: userData, error: userError } = await DataProvider.getUser()

      if (userError) {
        utils.app.remove('sessionToken')
      } else {
        setUser(userData.user)
        setUserConnected()
        DataProvider.getLists()
      }

      setState({ isLoging: false })
    }
  }

  const handleRecoveryPassword = async password => {
    setState({ isRecoveringPassword: true })

    const response = await DataProvider.resetPassword(password)

    setState({ isRecoveringPassword: false })

    if (response.error) {
      if (response.data.fields) {
        return Promise.reject(response.data.fields)
      } else {
        showNotification('error', intl`Mot de passe oublié`, intl`Une erreur est survenue.`)
      }
    } else {
      setState({ isPasswordModalActive: false })
      showNotification(
        'success',
        intl`Mot de passe oublié`,
        intl`Un email a été envoyé. Veuillez cliquer sur le lien qu'il contient pour finaliser la réinitialisation de votre mot de passe.`
      )
    }
  }

  const hasViewStateReady = useViewState({
    isLoging: false,
    isRecoveringPassword: false,
    loginFailed: false,
    isPasswordModalActive: false,
    handleLogin,
    handleRecoveryPassword
  })

  return hasViewStateReady ? (
    <LoginView />
  ) : (
    <>
      <Loader />
    </>
  )
}

export default Login
