import React, { Component } from 'react'
import dialogPolyfill from 'dialog-polyfill'
import 'dialog-polyfill/dialog-polyfill.css'
import styled from 'services/style'
import { ReactComponent as CloseIcon } from 'assets/svg/delete.svg'
import { CSS_VARS } from 'services/style'

const MainCSS = styled.dialog`
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 40px;
  width: 80%;
  border: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  @media (min-width: 768px) {
    width: fit-content;
    max-height: calc(100vh - 80px);
    height: fit-content;
  }
  &::backdrop,
  & + .backdrop {
    /* native */
    background-color: rgba(0, 0, 0, 0.4);
  }
  .Modal-close {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }
  .Modal-close-icon {
    width: 14px;
    height: 14px;
    fill: ${CSS_VARS.color_btn_secondary};
  }
  .Modal-inner {
    padding-bottom: 60px;
    max-height: calc(100vh - 80px);
    overflow-y: auto;
  }
`

// Props
//   onClose: PropTypes.func,
//   children: PropTypes.node

class Modal extends Component {
  constructor(props) {
    super(props)

    this.dialog = React.createRef()
  }

  componentDidMount() {
    dialogPolyfill.registerDialog(this.dialog.current)
    document.body.style.overflow = 'hidden'
    this.dialog.current.showModal()
  }

  componentWillUnmount() {
    document.body.style.overflow = 'auto'
    this.dialog.current.close()
  }

  render() {
    return (
      <MainCSS ref={this.dialog} className={`Modal ${this.props.className || ''}`}>
        <button className="Modal-close" onClick={this.props.onClose}>
          <CloseIcon className="Modal-close-icon" />
        </button>
        <div className="Modal-inner">{this.props.children}</div>
      </MainCSS>
    )
  }
}

export default Modal
