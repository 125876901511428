import * as React from 'react'
import styled, { CSS_VARS } from 'services/style'
import { ReactComponent as SuccessIcon } from 'assets/svg/success.svg'
import { ReactComponent as ErrorIcon } from 'assets/svg/error.svg'

const MainCSS = styled.div`
  position: relative;
  display: block;
  margin: auto;
  max-width: 450px;
  text-align: center;
  > .Notification-content {
    background-color: #ffffff;
    > h2 {
      margin-top: 20px;
    }

    > svg {
      width: 70px;
    }

    > hr {
      margin: 40px auto;
      width: 50px;
      border: 1px ${CSS_VARS.color_black} solid;
    }

    > p {
      line-height: 1.4;
    }
  }
`

function Notification(props) {
  let icon
  const { title, type, children, button } = props

  if (type === 'success') {
    icon = <SuccessIcon />
  } else if (type === 'warning' || type === 'error') {
    icon = <ErrorIcon />
  }

  return (
    <MainCSS className="Notification">
      <div className="Notification-content">
        {icon}
        {title && (
          <>
            <h2 className="psa-headboard">{title}</h2>
            {children && <hr />}
          </>
        )}
        {children}
      </div>
      {button && (
        <button
          type="button"
          onClick={button.handler}
          className="psa-button isPrimary Notification-action"
        >
          {button.title}
        </button>
      )}
    </MainCSS>
  )
}

export default Notification
