import React from 'react'
import { Redirect } from 'react-router-dom'
import { setUserDisconnected } from 'services/store/actions'
import utils from 'services/utils'

const Logout = () => {
  utils.app.remove('sessionToken')
  setUserDisconnected()
  return <Redirect to="/" />
}

export default Logout
