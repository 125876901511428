import { SET_STATE, CLEAR_STATE, SHOW_NOTIFICATION, HIDE_NOTIFICATION } from '../actions'

const getInitialState = () => {
  return {
    _toMemoize: false,
    _isReset: null,
    notification: null
  }
}

function reducer(state = getInitialState(), action) {
  switch (action.type) {
    case SET_STATE:
      return { ...state, ...action.state }
    case SHOW_NOTIFICATION:
      return { ...state, notification: action.notification }
    case HIDE_NOTIFICATION:
      return { ...state, notification: null }
    case CLEAR_STATE:
      return getInitialState()
    default:
      return state
  }
}

export default [reducer, getInitialState()]
