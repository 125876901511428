import React from 'react'
import { connect } from 'services/store'
import intl from 'services/intl'
import styled, { CSS_VARS, getFont } from 'services/style'
import utils from 'services/utils'
import { setState } from 'services/store/actions'
import { Form, Field, LoaderInline, Modal } from 'components'

const MainCSS = styled.div`
  max-width: 290px;
  text-align: center;
  .lpml-title {
    margin-bottom: 30px;
    ${getFont('RC', '400', 16, true)};
  }
  .lpml-label {
    margin-bottom: 30px;
    color: ${CSS_VARS.color_text_light};
    ${getFont('OS', 600, 12)};
    line-height: 1.6;
  }
  .lpml-input {
    margin: 0 auto;
    max-width: 250px;
  }
  .lpml-submit {
    margin-top: 20px;
  }
`

const LoginView = ({ isPasswordModalActive, isRecoveringPassword, handleRecoveryPassword }) => {
  const handleSubmit = fields => {
    const fieldsByName = utils.getFieldsByName(fields)
    const data = {
      email: fieldsByName.email
    }

    return handleRecoveryPassword(data)
  }

  if (!isPasswordModalActive) return null

  return (
    <Modal onClose={() => setState({ isPasswordModalActive: false })}>
      <MainCSS>
        <div className="lpml-title">{intl`Mot de passe oublié`}</div>
        <p className="lpml-label">{intl`Afin de procéder à la récupération de votre mot de passe, merci de saisir votre addresse mail :`}</p>
        <Form onSubmit={handleSubmit}>
          <Field>
            <input
              type="text"
              id="email"
              name="email"
              className="fg-inputText lpml-input"
              data-required-type="email"
              data-required={true}
            />
          </Field>
          {isRecoveringPassword ? (
            <LoaderInline />
          ) : (
            <button className="fg-btn isSecondary lpml-submit">{intl`Valider`}</button>
          )}
        </Form>
      </MainCSS>
    </Modal>
  )
}

const mapPropsToState = ({
  view: { isPasswordModalActive, isRecoveringPassword, handleRecoveryPassword }
}) => {
  return {
    isPasswordModalActive,
    isRecoveringPassword,
    handleRecoveryPassword
  }
}

export default connect(mapPropsToState)(LoginView)
