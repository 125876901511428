import { dispatch } from './index'

// Store
export const CLEAR_STORE = 'CLEAR_STORE'

export function setUserDisconnected() {
  dispatch({ type: CLEAR_STORE })
}

// App
export const SET_USER_CONNECTED = 'SET_USER_CONNECTED'
export const SET_LISTS = 'SET_LISTS'
export const SET_PASSWORD = 'SET_PASSWORD'

export function setUserConnected() {
  dispatch({ type: SET_USER_CONNECTED })
}

export function setLists(lists) {
  dispatch({ type: SET_LISTS, lists })
}

export function setPassword(password) {
  dispatch({ type: SET_PASSWORD, password })
}

// View
export const SET_STATE = 'SET_STATE'
export const CLEAR_STATE = 'CLEAR_STATE'
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION'

export function setState(state) {
  dispatch({ type: SET_STATE, state })
}

export function clearState() {
  dispatch({ type: CLEAR_STATE })
}

export function showNotification(type, title, text = null, button = null, textHtml = false) {
  // Delay to allow to diplay the notification after changing page
  setTimeout(
    () =>
      dispatch({ type: SHOW_NOTIFICATION, notification: { type, title, text, button, textHtml } }),
    100
  )
}

export function hideNotification() {
  dispatch({ type: HIDE_NOTIFICATION })
}

// User
export const SET_USER = 'SET_USER'
export const CLEAR_USER = 'CLEAR_USER'

export function setUser(user) {
  dispatch({ type: SET_USER, user })
}

export function clearUser() {
  dispatch({ type: CLEAR_USER })
}
