import { CLEAR_STORE, SET_LISTS, SET_PASSWORD, SET_USER_CONNECTED } from '../actions'

const getInitialState = () => {
  return {
    isAuthenticated: false
  }
}

function reducer(state = getInitialState(), action) {
  switch (action.type) {
    case CLEAR_STORE:
      return getInitialState()
    case SET_LISTS:
      return { ...state, lists: action.lists }
    case SET_PASSWORD:
      return { ...state, password: action.password }
    case SET_USER_CONNECTED:
      return { ...state, isAuthenticated: true }
    default:
      return state
  }
}

export default [reducer, getInitialState()]
