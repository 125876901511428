import { useState, useEffect } from 'react'
import { storeListener } from 'services/store'
import { clearState, setState } from 'services/store/actions'

export default function useViewState(newState) {
  const [isReset, setReset] = useState(false)
  let souscription

  const initViewState = () => {
    if (isReset === false) {
      souscription = storeListener.subscribe({
        next: state => {
          if (state.view) {
            if (state.view._isReset) {
              setReset(true)
              souscription.unsubscribe()
            } else {
              setState({ _isReset: true, ...newState })
            }
          }
        }
      })

      clearState()

      return () => {
        if (!isReset) {
          if (souscription) souscription.unsubscribe()
        }
      }
    }
  }

  useEffect(initViewState)

  return isReset
}
