import React, { Component } from 'react'
import dayjs from 'dayjs'
import styled, { CSS_VARS, getFont } from 'services/style'
import { ReactComponent as CornerLeftIcon } from 'assets/svg/arrow-left.svg'
import { ReactComponent as CornerRightIcon } from 'assets/svg/arrow-right.svg'

const MainCSS = styled.div`
  display: inline-block;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  button {
    outline: none;
  }
  > nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
    ${getFont('OS', 600, 14)};
    background-color: #eeeeee;
    border-bottom: 1px solid #cccccc;

    > button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      &:focus {
        background-color: #e6e6e6;
      }
      > svg {
        width: 14px;
        height: 14px;
        fill: #cccccc;
        &:hover {
          fill: #b3b3b3;
        }
      }
    }
    > .selectInputs {
      display: flex;
      > select {
        margin: 0 5px;
        border-radius: 10px;
        text-transform: capitalize;
      }
    }
  }
  > table {
    width: 100%;
    th,
    td {
      text-align: center;
      vertical-align: middle;
    }
    th {
      font-size: 10px;
      text-transform: uppercase;
    }
    td {
      button {
        position: relative;
        width: 25px;
        height: 25px;
        font-size: 13px;
        border-radius: 50%;

        &:focus {
          background-color: #e6e6e6;
        }
        &.isDate {
          color: ${CSS_VARS.color_btn_primary};
        }
        &.isSelected {
          color: #ffffff;
          background-color: ${CSS_VARS.color_btn_primary};
        }
        &.isDisabled {
          opacity: 0.3;
        }
      }
    }
  }
`

class DayAndMonthPicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      now:
        props.date || props.maxDate ? dayjs(props.date || props.maxDate) : dayjs().startOf('day'),
      date: props.date ? dayjs(props.date) : null,
      minDate: props.minDate ? dayjs(props.minDate) : null,
      maxDate: props.maxDate ? dayjs(props.maxDate) : null
    }
  }

  render() {
    const weeks = this.getWeeks()
    const { now, date, minDate, maxDate } = this.state

    return (
      <MainCSS className="datepicker">
        <nav>
          <button type="button" onClick={this.prev}>
            <CornerLeftIcon />
          </button>
          <div className="selectInputs">
            <select
              value={now.format('M') - 1}
              onChange={event => {
                this.navigate(now.set('month', event.target.value))
              }}
            >
              {[...Array(12).keys()].map((val, i) => {
                const month = dayjs()
                  .startOf('year')
                  .add(i, 'months')
                  .format('MMMM')
                return (
                  <option value={i} key={month}>
                    {month}
                  </option>
                )
              })}
            </select>
            <select
              value={now.format('YYYY')}
              onChange={event => {
                this.navigate(now.set('year', event.target.value))
              }}
            >
              {[...Array(101).keys()].map((val, i) => {
                const year = dayjs().get('year') - i
                return <option key={year}>{year}</option>
              })}
            </select>
          </div>
          <button type="button" onClick={this.next}>
            <CornerRightIcon />
          </button>
        </nav>
        <table>
          <thead>
            <tr>
              <th>LUN</th>
              <th>MAR</th>
              <th>MER</th>
              <th>JEU</th>
              <th>VEN</th>
              <th>SAM</th>
              <th>DIM</th>
            </tr>
          </thead>
          <tbody>
            {weeks.map((week, r) => {
              return (
                <tr key={r}>
                  {week.map((d, r) => {
                    const isDisabled =
                      (minDate && d.isBefore(minDate, 'day')) ||
                      (maxDate && d.isAfter(maxDate, 'day'))
                    return (
                      <td key={r}>
                        <button
                          type="button"
                          className={`${isDisabled ? 'isDisabled' : ''}
                           ${dayjs().isSame(d) ? ' isDate' : ''} ${
                            date && date.isSame(d) ? ' isSelected' : ''
                          }`}
                          onClick={() => {
                            if (!isDisabled) {
                              this.selectDate(d)
                            }
                          }}
                        >
                          {d.date()}
                        </button>
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </MainCSS>
    )
  }

  getWeeks() {
    const startMonth = this.state.now ? this.state.now.startOf('month') : dayjs().startOf('month')
    const currentDay = startMonth.day()
    const endMonth = startMonth.endOf('month').day()
    const days = []

    const push = function(start, end) {
      for (let i = 0; i < end.diff(start, 'day') + 1; i++) {
        days.push(start.add(i, 'day'))
      }
    }

    // Generates the days for the previous month
    if (currentDay) {
      push(startMonth.startOf('week'), startMonth.subtract('1', 'day'))
    }

    // Generates the days for the current month
    push(startMonth, startMonth.endOf('month'))

    // Generates the days for the next month
    if (endMonth < 6) {
      push(
        startMonth.endOf('month').add(1, 'day'),
        startMonth
          .endOf('month')
          .endOf('week')
          .add(1, 'day')
      )
    }

    const week = []

    days.forEach((d, i) => {
      const ti = Math.floor(i / 7)

      if (week[ti]) {
        week[ti].push(d)
      } else {
        week[ti] = [d]
      }
    })

    return week
  }

  prev = () => {
    if (
      this.state.minDate &&
      this.state.now.subtract(1, 'month').isBefore(this.state.minDate, 'month')
    )
      return

    this.setState(state => {
      return {
        now: state.now.subtract(1, 'month')
      }
    })
  }

  next = () => {
    if (this.state.maxDate && this.state.now.add(1, 'month').isAfter(this.state.maxDate, 'month'))
      return

    this.setState(state => {
      return {
        now: state.now.add(1, 'month')
      }
    })
  }

  navigate(now) {
    this.setState({ now })
  }

  selectDate(date) {
    this.setState({ date })
    if (this.props.onChange) {
      this.props.onChange(date)
    }
  }
}

export default DayAndMonthPicker
